import React from "react";
import SectionHeader from "../components/SectionHeader";
import styled from "styled-components";
import { palette } from "../styles/globalStyle";
import { EnvelopeSimpleOpen, PaperPlaneTilt } from "@phosphor-icons/react";
import { contactDetails } from "../Constants/contentData";
import ContactWidget from "../components/ContactWidget";
import { useNavigate } from "react-router-dom";

function ContactUsSection() {
  const navigate = useNavigate();

  const contacts = [
    {
      label: "Email",
      icon: (
        <EnvelopeSimpleOpen
          color={palette.Base[50]}
          weight="duotone"
          size={48}
        />
      ),
      value: contactDetails.email,
    },
    {
      label: "Numéro de téléphone",
      icon: (
        <EnvelopeSimpleOpen
          color={palette.Base[50]}
          weight="duotone"
          size={48}
        />
      ),
      value: contactDetails.phone,
    },
    {
      label: "Localisation",
      icon: (
        <EnvelopeSimpleOpen
          color={palette.Base[50]}
          weight="duotone"
          size={48}
        />
      ),
      value: contactDetails.adress,
    },
  ];
  return (
    <Wrapper>
      <SectionHeader title="Vous avez un projet ?" label="Contact" />
      <BlocsWrapper>
        {contacts.map((contact, index) => (
          <ContactWidget contact={contact} key={index} />
        ))}
      </BlocsWrapper>
      <SectionDivider />
      <StyledButtonMobile onClick={() => navigate("/contactUs")}>
        Contactez-nous
        <PaperPlaneTilt
          size={16}
          color={palette.Primary[500]}
          weight="duotone"
        />
      </StyledButtonMobile>
    </Wrapper>
  );
}

export default ContactUsSection;

const Wrapper = styled.div`
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const BlocsWrapper = styled.div`
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  gap: 34px;
  margin: 30px 0;
`;

const SectionDivider = styled.div`
  width: 90%;
  height: 1px;
  background-color: #eef9ff;
`;

const StyledButtonMobile = styled.button`
  color: #34a8fd;
  width: 90%;
  text-align: center;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  border-radius: 8px;
  border: 1px solid #34a8fd;
  background-color: #fff;
  padding: 16px;
  margin: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;
