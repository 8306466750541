import React from "react";
import HeroSection from "../containers/HeroSection";
import Projects from "../containers/ProjectsSection";
import ContactUsSection from "../containers/ContactUsSection";
import ServicesSection from "../containers/ServicesSection";
import AboutUsSection from "../containers/AboutUsSection";
import ProjectStepsSection from "../containers/ProjectStepsSection";
import styled from "styled-components";
import ReviewsSection from "../containers/ReviewsSection";
import LogosSection from "../containers/LogosSection";
import { Helmet } from "react-helmet";

function Home() {
  return (
    <div>
      <Helmet>
        <title>KIAM TECH - Créateurs de Solutions Digitales Innovantes</title>
        <meta
          name="description"
          content="Bienvenue chez KIAMTECH ! Nous développons des applications mobiles, des sites web et des solutions digitales sur mesure pour répondre aux besoins de votre entreprise."
        />
      </Helmet>
      <HeroSection />
      <Gradient />
      <LogosSection />
      <Projects />
      <ServicesSection />
      <AboutUsSection />
      <ProjectStepsSection />
      {/* <ReviewsSection /> */}
      <ContactUsSection />
    </div>
  );
}

export default Home;

const Gradient = styled.div`
  /* position: absolute; */
  width: 100%;
  height: 100px;
  background: linear-gradient(to bottom, #000, transparent);
  /* bottom: 0;
  left: 0;
  right: 0; */
  @media (max-width: 780px) {
    position: inherit;
    background: linear-gradient(to bottom, #000, transparent);
    height: 90px;
  }
`;
