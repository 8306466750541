import React from "react";
import styled from "styled-components";
import { palette } from "../styles/globalStyle";
import ContactUsSection from "../containers/ContactUsSection";
import ProjectsSection from "../containers/ProjectsSection";
import { projects } from "../Constants/contentData";
import { useNavigate, useParams } from "react-router-dom";
import { Browser } from "@phosphor-icons/react";
import { Helmet } from "react-helmet";

function ProjectDetails() {
  const navigate = useNavigate();
  const { id } = useParams();

  const project = projects.find((project) => project.id == id);

  const handleOpenUrl = (url) => {
    window.open(url, "_blank");
  };

  return (
    <Wrapper>
      <Helmet>
        <title>{project.name} - KIAM TECH</title>
        <meta name="description" content={project.desc} />
      </Helmet>
      <ProjectDetailsWrapper>
        <WrapperLeft>
          <TextWrapper>
            <Badge>{project.bagdeTxt}</Badge>
            <ProjectTitle>{project.name}</ProjectTitle>
            <ProjectDesc>{project.desc}</ProjectDesc>
            <ContactButton
              primary
              onClick={() => {
                handleOpenUrl("https://www.gerermonpermis.com");
              }}
            >
              <Browser
                size={16}
                color={palette.Primary[500]}
                weight="duotone"
              />
              www.gerermonpermis.com
            </ContactButton>
          </TextWrapper>
        </WrapperLeft>
        <WrapperRight>
          <ImageWrapper>
            <Image
              height="auto"
              width="100%"
              src="/assets/images/project1_1.png"
            />
          </ImageWrapper>
          <ImageWrapperBottom>
            <img
              height="auto"
              width="42%"
              src="/assets/images/project1_3.webp"
              alt="project_image"
            />
            <img
              height="auto"
              width="56%"
              src="/assets/images/project1_2.webp"
              alt="project_image"
            />
          </ImageWrapperBottom>
        </WrapperRight>
      </ProjectDetailsWrapper>
      <ContactUsSection />
      <ProjectsSection />
    </Wrapper>
  );
}

export default ProjectDetails;

const Wrapper = styled.div`
  margin-top: 30px;

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 32px 32px 0px 0px;
  border-top: 1px solid rgba(238, 249, 255, 0.3);
  border-left: 1px solid rgba(238, 249, 255, 0.3);
  border-right: 1px solid rgba(238, 249, 255, 0.3);
  overflow: hidden;
`;
const ProjectDetailsWrapper = styled.div`
  margin-top: 30px;
  padding: 0 80px;
  width: 100%;
  display: flex;
  align-items: start;
  gap: 24px;
  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 0 24px;
  }
`;
const WrapperLeft = styled.div`
  margin-top: 30px;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;
const WrapperRight = styled.div`
  margin-top: 30px;
  width: 50%;
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const Badge = styled.text`
  padding: 8px;
  color: var(--colors-system-base-50-white, #fff);
  border-radius: 72px;
  border: 1px solid rgba(238, 249, 255, 0.4);
  background: var(--Colors-Brand-Gray-800, #1f2937);
  text-align: center;

  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;
const ProjectTitle = styled.text`
  color: var(--Colors-Brand-Primary-100, #daf1ff);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.64px;
`;
const ProjectDesc = styled.text`
  color: var(--colors-system-base-400-darkgray, #989898);
  font-family: Satoshi;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  text-align: justify;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 14px;
`;

const ImageWrapper = styled.div`
  display: inline-flex;
  padding: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  width: 100%;
  background: var(--Colors-System-Base-900, #3d3d3d);
`;
const Image = styled.img`
  /* flex-grow: 1; */
`;
const ImageWrapperBottom = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2%;
  width: 100%;
  height: auto;
`;

const ContactButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  color: #34a8fd;
  text-align: center;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  border-radius: var(--Border-Radius-M, 8px);
  border: 1px solid #34a8fd;
  background-color: #fff;
  padding: 16px 60px;
  width: 100%;
  @media (max-width: 780px) {
    width: 90%;
  }
`;

const LinkWrapper = styled.button`
  background-color: transparent;
  padding: 0;
  border-radius: 12px;
  &:hover {
    background-color: #9bc4f4; // Default hover color
  }
`;
