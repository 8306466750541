import React, { useEffect } from "react";
import styled from "styled-components";
import { services } from "../Constants/contentData";
import ContactUsSection from "../containers/ContactUsSection";
import { useParams, useSearchParams, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

function Services() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const serviceId = queryParams.get("serviceId");

  function scrollToSection(sectionId) {
    const section = document.getElementById(`service-${sectionId}`);
    section.scrollIntoView({ behavior: "smooth" });
  }

  useEffect(() => {
    if (serviceId) {
      scrollToSection(serviceId);
    }
  }, [serviceId]);

  return (
    <>
      <Helmet>
        <title>Nos Services - KIAM TECH</title>
        <meta
          name="description"
          content="Découvrez les services offerts par KIAMTECH : développement d'applications mobiles, création de sites web, et solutions digitales innovantes pour améliorer votre entreprise."
        />
      </Helmet>
      <Header>
        <TxtWrapper>
          <Title>Comment nous pouvons vous aider</Title>
          <Desc>
            Kiam Tech conçoit et développe des solutions digitales sur mesure
            qui transforment les entreprises. Notre expertise en React, Python,
            et les technologies de pointe nous permet d'offrir une gamme de
            services adaptés à vos défis spécifiques.
          </Desc>
        </TxtWrapper>
        <Image src="/assets/services.svg" />
      </Header>
      <Wrapper>
        <ServicesWrapper>
          {services.map((service, index) => (
            <Service id={`service-${service.id}`} key={service.id}>
              <img src={service.iconPath} alt="icon" />
              <ServiceTitle>{service.title}</ServiceTitle>
              <ServiceDesc>{service.desc}</ServiceDesc>
            </Service>
          ))}
        </ServicesWrapper>
        <ContactUsSection />
      </Wrapper>
    </>
  );
}

export default Services;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 60px;
  flex-direction: column;
  align-items: flex-start;
  border-left: 1px solid rgba(238, 249, 255, 0.3);
  border-right: 1px solid rgba(238, 249, 255, 0.3);
  overflow: hidden;
  @media (max-width: 780px) {
    padding: 16px;
  }
`;
const Header = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  padding: 80px;
  gap: 16px;
  align-items: center;
  border-radius: 32px 32px 0 0;
  border-top: 1px solid rgba(238, 249, 255, 0.3);
  border-right: 1px solid rgba(238, 249, 255, 0.3);
  border-left: 1px solid rgba(238, 249, 255, 0.3);
  background: linear-gradient(
    180deg,
    rgba(238, 249, 255, 0.3) 0%,
    rgba(238, 249, 255, 0) 100%
  );
  @media (max-width: 780px) {
    flex-direction: column;
    padding: 16px;
  }
`;
const Image = styled.img``;
const Title = styled.text`
  color: var(--colors-system-base-50-white, #fff);

  font-size: 44px;
  font-style: normal;
  font-weight: 900;
  line-height: 48px;
  letter-spacing: -0.88px;
  text-align: start;
  @media (max-width: 780px) {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.64px;
  }
`;
const TxtWrapper = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: column;
  gap: 16px;
  max-width: 600px;
`;
const Desc = styled.text`
  color: var(--colors-system-base-400-darkgray, #989898);

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  text-align: start;
  @media (max-width: 780px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const ServicesWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  margin-top: 30px;
`;
const Service = styled.div`
  flex-grow: 1;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  gap: 28px;
  border-radius: 32px;
  border: 2px solid rgba(238, 249, 255, 0.1);
  backdrop-filter: blur(2px);
  white-space: normal;
  @media (max-width: 780px) {
  }
`;

const ServiceTitle = styled.text`
  color: var(--colors-system-base-50-white, #fff);

  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.64px;
  text-align: start;
  white-space: normal;
  @media (max-width: 780px) {
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.56px;
  }
`;
const ServiceDesc = styled.text`
  color: var(--colors-system-base-400-darkgray, #989898);

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.4px;
  text-align: start;
  white-space: normal;
  @media (max-width: 780px) {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.36px;
  }
`;
