import React from "react";
import styled from "styled-components";

const HorizontalScrollView = styled.div`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  display: flex;
  gap: 16px;
  margin: 30px;
  padding: 30px;
`;

const HorizontalScrollContainer = ({ children }) => {
  return <HorizontalScrollView>{children}</HorizontalScrollView>;
};

export default HorizontalScrollContainer;
