import styled, { keyframes } from "styled-components";

// Définissez l'animation de défilement
const scrollAnimation = keyframes`
  0% {
    transform: translateX(-75%);
  }
  100% {
    transform: translateX(0%);
  }
`;

const Container = styled.div`
  width: 100%;
  position: absolute;
  top: 30%;
`;

const Items = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 0;
  animation: ${scrollAnimation} 40s linear infinite;
  animation-direction: alternate;
  background: var(--Colors-Brand-Primary-50, #eef9ff);
  width: 2500px;
`;

const ThinText = styled.text`
  color: var(--Colors-Brand-Primary-700, #1776e5);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.4px;
  text-transform: uppercase;
  @media (max-width: 780px) {
    font-size: 12px;
    letter-spacing: -0.24px;
  }
`;
const BoldText = styled.text`
  color: var(--Colors-Brand-Primary-500, #34a8fd);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: 28px;
  letter-spacing: -0.4px;
  text-transform: uppercase;
  @media (max-width: 780px) {
    font-size: 12px;
    letter-spacing: -0.24px;
  }
`;
const Polygon = styled.img`
  margin: 0 8px;
`;

const InfiniteMovingBande = () => {
  return (
    <Container>
      <Items>
        <ThinText>Projet à venir</ThinText>
        <Polygon src="/assets/darkPolygon.svg" alt="icon" />
        <BoldText>Projet à venir</BoldText>
        <Polygon src="/assets/lightPolygon.svg" alt="icon" />
        <ThinText>Projet à venir</ThinText>
        <Polygon src="/assets/darkPolygon.svg" alt="icon" />
        <BoldText>Projet à venir</BoldText>
        <Polygon src="/assets/lightPolygon.svg" alt="icon" />
        <ThinText>Projet à venir</ThinText>
        <Polygon src="/assets/darkPolygon.svg" alt="icon" />
        <BoldText>Projet à venir</BoldText>
        <Polygon src="/assets/lightPolygon.svg" alt="icon" />
        <ThinText>Projet à venir</ThinText>
        <Polygon src="/assets/darkPolygon.svg" alt="icon" />
        <BoldText>Projet à venir</BoldText>
        <Polygon src="/assets/lightPolygon.svg" alt="icon" />
        <ThinText>Projet à venir</ThinText>
        <Polygon src="/assets/darkPolygon.svg" alt="icon" />
        <BoldText>Projet à venir</BoldText>
        <Polygon src="/assets/lightPolygon.svg" alt="icon" />
        <ThinText>Projet à venir</ThinText>
        <Polygon src="/assets/darkPolygon.svg" alt="icon" />
        <BoldText>Projet à venir</BoldText>
        <Polygon src="/assets/lightPolygon.svg" alt="icon" />
      </Items>
    </Container>
  );
};

export default InfiniteMovingBande;
