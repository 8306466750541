import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, NavLink } from "react-router-dom";
import { palette } from "../styles/globalStyle";
import {
  ArrowCircleUp,
  InstagramLogo,
  LinkedinLogo,
  XLogo,
} from "@phosphor-icons/react";
import {
  contactDetails,
  pages,
  policyLinks,
  services,
} from "../Constants/contentData";

function Footer() {
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
    const handleResize = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addEventListener("change", handleResize);

    return () => mediaQuery.removeEventListener("change", handleResize);
  }, []);

  const handleOpenUrl = (url) => {
    window.open(url, "_blank");
  };

  return (
    <Wrapper>
      <NavBtnWrapper>
        <img
          onClick={() => navigate("/")}
          src={"/assets/logos/logo.svg"}
          alt="website logo svg"
          style={{ width: isMobile ? "141px" : null }}
        />
        <LinksWrapper>
          {pages.map((page) => (
            <NavLink
              key={page}
              to={page.path}
              activeStyle={{ color: "red" }}
              style={({ isActive, isPending, isTransitioning }) => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
                return {
                  margin: "0 24px",
                  textDecoration: "none",
                  color: isActive ? palette.Primary[500] : palette.Base[50],
                  fontFamily: "Satoshi",
                  fontWeight: 400,
                  fontSize: 18,
                  backgroundColor: "transparent",
                };
              }}
            >
              {page.label}
            </NavLink>
          ))}
        </LinksWrapper>
        <BackToTopWrapper>
          <BackToTopTxt
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            Retour au sommet
          </BackToTopTxt>
          <ArrowCircleUp color="#34a8fd" weight="duotone" size={16} />
        </BackToTopWrapper>
      </NavBtnWrapper>
      <GroupsWrapper>
        <Group>
          <GroupTitle>Email</GroupTitle>
          <Link>{contactDetails.email}</Link>
          <GroupTitle>Numéro de téléphone</GroupTitle>
          <Link>{contactDetails.phone}</Link>
          <GroupTitle>Localisation</GroupTitle>
          <Link>{contactDetails.adress}</Link>
        </Group>
        <Group>
          <GroupTitle>Services</GroupTitle>

          {services.map((service, index) => (
            <Link
              key={index}
              onClick={() => {
                navigate(`/services?serviceId=${service.id}`);
              }}
            >
              {service.title}
            </Link>
          ))}
        </Group>
        <Group>
          <GroupTitle>Légal</GroupTitle>
          {policyLinks.map((link, index) => (
            <Link
              key={index}
              onClick={() => {
                navigate(link.path);
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            >
              {link.label}
            </Link>
          ))}
        </Group>
        <Group>
          <GroupTitle>Sociaux</GroupTitle>
          <IconsWrapper>
            <LinkWrapper>
              <InstagramLogo
                color={palette.Base[50]}
                weight="duotone"
                size={48}
                onClick={() => {
                  handleOpenUrl(
                    "https://www.instagram.com/kiam.tech/"
                  );
                }}
              />
            </LinkWrapper>
            <LinkWrapper>
              <LinkedinLogo color={palette.Base[50]} weight="duotone" size={48}
              onClick={() => {
                handleOpenUrl(
                  "https://www.linkedin.com/company/kiam-tech/"
                );
              }} />
            </LinkWrapper>
            <LinkWrapper>
              <XLogo color={palette.Base[50]} weight="duotone" size={48}
              onClick={() => {
                handleOpenUrl(
                  "https://x.com/KiamTech"
                );
              }} />
            </LinkWrapper>
          </IconsWrapper>
        </Group>
      </GroupsWrapper>
      <Copyright>KiamTech@Copyright 2024</Copyright>
    </Wrapper>
  );
}

export default Footer;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 36px;
  margin-top: 30px;
  border-top-right-radius: 32px;
  border-top-left-radius: 32px;
  border-top: 1px solid rgba(238, 249, 255, 0.3);
  background: linear-gradient(
    180deg,
    rgba(238, 249, 255, 0.3) 0%,
    rgba(238, 249, 255, 0) 100%
  );
`;

const NavBtnWrapper = styled.div`
  width: 100%;
  border-radius: 12px;
  background: var(--colors-system-base-950-black, #292929);
  backdrop-filter: blur(304px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 38px;
  @media (max-width: 1200px) {
    flex-direction: column;
    gap: 16px;
  }
`;
const LinksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
const BackToTopWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
const BackToTopTxt = styled.a`
  color: var(--Colors-Brand-Primary-500, #34a8fd);
  text-align: center;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
`;

const GroupsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 46px;
  justify-content: center;
  @media (max-width: 615px) {
    justify-content: start;
    /* flex-direction: column; */
  }
`;
const Group = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  align-items: start;
  @media (max-width: 1230px) {
    /* width: 48%; */
    /* flex-basis: calc(100% / 2); */
    /* flex: 1 0 calc(50%); */
  }
`;

const GroupTitle = styled.text`
  color: var(--colors-system-base-400-darkgray, #989898);

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
`;
const Link = styled.button`
  color: var(--colors-system-base-50-white, #fff);
  text-align: start;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
  @media (max-width: 780px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
const Copyright = styled.text`
  color: var(--colors-system-base-400-darkgray, #989898);
  text-align: center;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
`;
const LinkWrapper = styled.button`
  background-color: transparent;
  padding: 0;
  border-radius: 12px;
  &:hover {
    background-color: #34a8fd; // Default hover color
  }
`;
