import React from "react";
import { motion } from "framer-motion";
import styled, { keyframes } from "styled-components";

function SectionHeader({ title, label }) {
  const hoverVariants = {
    initial: {
      background:
        "linear-gradient(270deg, rgba(238, 249, 255, 0.30) 0%, rgba(238, 249, 255, 0.00) 100%)",
    },
    hover: {
      background:
        "linear-gradient(270deg, rgba(238, 249, 255, 0.00) 0%, rgba(238, 249, 255, 0.30) 100%)",
    },
  };

  const wapperStyle = {
    display: "flex",
    width: "90%",
    padding: "24px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "16px",
    borderRadius: "var(--Border-Radius-XL, 24px)",
    borderTop: "1px solid rgba(238, 249, 255, 0.30)",
    borderRight: "1px solid rgba(238, 249, 255, 0.30)",
    borderLeft: "1px solid rgba(238, 249, 255, 0.30)",
  };
  return (
    <motion.div
      variants={hoverVariants}
      initial="initial"
      animate="initial"
      whileHover="hover"
      transition={{ type: "tween", duration: 0.3, ease: "linear" }} // Animation config
      style={wapperStyle}
    >
      <TagTxt>{label}</TagTxt>
      <Title>{title}</Title>
    </motion.div>
  );
}

export default SectionHeader;

const TagTxt = styled.text`
  color: #30a76e;
  text-align: start;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;
const Title = styled.text`
  color: #fff;

  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: 48px;
  text-align: start;
  letter-spacing: -0.8px;

  @media (max-width: 780px) {
    font-size: 36px;
    font-style: normal;
    font-weight: 900;
    line-height: 44px; /* 122.222% */
    letter-spacing: -0.72px;
  }
`;
