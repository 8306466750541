import React from "react";
import styled from "styled-components";
import { politicOfUsage } from "../Constants/contentData";
import ContactUsSection from "../containers/ContactUsSection";

function politicUsage() {
  return (
    <Wrapper>
      <Header>
        <Title>Politique d’Utilisation</Title>
      </Header>
      {politicOfUsage.map((section) => (
        <>
          <BodyTxt>{section}</BodyTxt>
          <br />
        </>
      ))}
      <ContactUsSection />
    </Wrapper>
  );
}

export default politicUsage;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  // align-items: center;
  align-items: flex-start;
`;

const Header = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  padding: 80px;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: 32px 32px 0px 0px;
  border-top: 1px solid rgba(238, 249, 255, 0.3);
  background: linear-gradient(
    180deg,
    rgba(238, 249, 255, 0.3) 0%,
    rgba(238, 249, 255, 0) 100%
  );
  @media (max-width: 768px) {
    padding: 50px 20px;
  }
`;

const Title = styled.text`
  color: var(--colors-system-base-50-white, #fff);
  align-self: self-start;

  font-size: 44px;
  font-style: normal;
  font-weight: 900;
  line-height: 48px;
  letter-spacing: -0.88px;
  @media (max-width: 500px) {
    font-size: 28px;
    font-weight: 700;
  }
`;

const BodyTxt = styled.text`
  color: var(--colors-system-base-400-darkgray, #989898);

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.4px;
  padding: 0 60px;
  text-align: justify;
  @media (max-width: 768px) {
    font-size: 18px;
    padding: 0 16px;
  }
`;
