import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import SectionHeader from "../components/SectionHeader";
import { projectSteps } from "../Constants/contentData";
import HorizontalScrollContainer from "../components/HorizontalScrollView";

function ProjectStepsSection() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 780px)");
    setIsMobile(mediaQuery.matches);
    const handleResize = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addEventListener("change", handleResize);

    return () => mediaQuery.removeEventListener("change", handleResize);
  }, []);
  return (
    <Wrapper>
      <SectionHeader label="Processus" title="Comment ça fonctionne" />
      {isMobile ? (
        <HorizontalScrollContainer>
          {projectSteps.map((step, index) => (
            <Step>
              {index % 2 === 0 && (
                <BgPattern src="/assets/redCircle.svg" alt="bg pattern" />
              )}
              <Title>
                {index + 1}. {step.title}
              </Title>
              <Desc>{step.desc}</Desc>
            </Step>
          ))}
        </HorizontalScrollContainer>
      ) : (
        <StepWrapper>
          {projectSteps.map((step, index) => (
            <Step>
              {step.withPattern && (
                <BgPattern src="/assets/redCircle.svg" alt="bg pattern" />
              )}
              <Title>
                {index + 1}. {step.title}
              </Title>
              <Desc>{step.desc}</Desc>
            </Step>
          ))}
        </StepWrapper>
      )}
    </Wrapper>
  );
}

export default ProjectStepsSection;

const Wrapper = styled.div`
  margin: 24px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const BgPattern = styled.img`
  position: absolute;
  left: 0;
  top: 0;
`;

const StepWrapper = styled.div`
  width: 90%;
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  margin: 30px 0;
`;
const Step = styled.div`
  flex-grow: 1;
  position: relative;
  display: flex;
  width: 48%;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex-shrink: 0;
  border-radius: 16px;
  border-top: 1px solid rgba(238, 249, 255, 0.3);
  border-right: 1px solid rgba(238, 249, 255, 0.3);
  border-left: 1px solid rgba(238, 249, 255, 0.3);
  background: linear-gradient(
    180deg,
    rgba(253, 244, 239, 0.15) 0%,
    rgba(250, 230, 218, 0.1) 100%
  );
  white-space: normal;
  @media (max-width: 780px) {
    width: 100%;
    /* margin-right: 20px; */
  }
`;
const Title = styled.text`
  color: var(--Colors-Brand-Primary-50, #eef9ff);

  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.64px;
  text-align: start;
  @media (max-width: 780px) {
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.56px;
  }
`;
const Desc = styled.text`
  color: var(--colors-system-base-400-darkgray, #989898);

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  text-align: start;
  @media (max-width: 780px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
