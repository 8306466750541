import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import { Alert } from "@mui/material";
import styled from "styled-components";
import { Bug, CheckSquareOffset } from "@phosphor-icons/react";

function SlideTransition(props) {
  return <Slide {...props} direction="right" />;
}

export default function Toast({
  isToastOpen,
  setIsToastOpen,
  message,
  variant,
}) {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsToastOpen(false);
  };

  const bgColor = {
    success: "#DCFCE8",
    error: "#FEE2E2",
  };
  const fgColor = {
    success: "#16A34A",
    error: "#DC2626",
  };
  const icon = {
    success: <CheckSquareOffset color={"#16A34A"} weight="duotone" size={28} />,
    error: <Bug color={"#DC2626"} weight="duotone" size={28} />,
  };

  return (
    <div>
      <Snackbar
        open={isToastOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        TransitionComponent={SlideTransition}
        transitionDuration={500}
      >
        <Alert
          onClose={handleClose}
          icon={icon[variant]}
          sx={{
            backgroundColor: bgColor[variant],
            color: fgColor[variant],
          }}
        >
          <Message>{message}</Message>
        </Alert>
      </Snackbar>
    </div>
  );
}

const Message = styled.text`
  margin-right: 16px;
`;
