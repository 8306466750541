import React from "react";
import styled from "styled-components";

function LogosSection() {
  return (
    <LogosWrapper>
      <img src={"/assets/logos/gererMonPermis.svg"} alt="logo" />
      <img src={"/assets/logos/gererMesVehicules.svg"} alt="logo" />
    </LogosWrapper>
  );
}

export default LogosSection;

const LogosWrapper = styled.div`
  width: 100%;
  padding-bottom: 100px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  padding: 60px 16px;
  background-color: transparent;
  @media (max-width: 780px) {
    gap: 24px;
  }
`;
