import React from "react";
import { LampContainer } from "./LampEffect";
import { motion } from "framer-motion";
import VibratedParticle from "./VibratedParticle";
import styled from "styled-components";
import { palette } from "../styles/globalStyle";

function CommingSoon() {
  return (
    <Wrapper>
      <LampContainer>
        {/* <VibratedParticle /> */}
        <motion.h1
          initial={{ opacity: 0.5, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{
            delay: 0.3,
            duration: 0.8,
            ease: "easeInOut",
          }}
          className="text-white text-center leading-trim text-cap font-satoshi font-bold text-7xl leading-[140%] tracking-tight"
        >
          Comimg Soon...
        </motion.h1>
      </LampContainer>
    </Wrapper>
  );
}

export default CommingSoon;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
