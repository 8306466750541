import React from "react";
import styled, { keyframes } from "styled-components";
import SectionHeader from "../components/SectionHeader";
import HorizontalScrollContainer from "../components/HorizontalScrollView";
import { reviews } from "../Constants/contentData";

function ReviewsSection() {
  return (
    <Wrapper>
      <SectionHeader label="Testimonials" title="Ce que disent nos clients" />
      <HorizontalScrollContainer>
        {reviews.map((step, index) => (
          <Review>
            <Title>{step.title}</Title>
            <Desc>{step.comment}</Desc>
            <AuthorWrapper>
              <BgPattern src={step.profilePicture} alt="client profile" />
              <AuthorName>{step.author}</AuthorName>
            </AuthorWrapper>
          </Review>
        ))}
      </HorizontalScrollContainer>
    </Wrapper>
  );
}

export default ReviewsSection;
const Wrapper = styled.div`
  margin: 24px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const AuthorWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 16px;
  gap: 14px;
  align-items: center;
  flex-direction: row;
`;
const BgPattern = styled.img`
  width: 52px;
  aspect-ratio: 1/1;
`;

const ReviewWrapper = styled.div`
  width: 90%;
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  margin: 30px 0;
`;
const Review = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 300px;
  border-radius: 32px;
  border-right: 1px solid rgba(238, 249, 255, 0.3);
  border-bottom: 1px solid rgba(238, 249, 255, 0.3);
  border-left: 1px solid rgba(238, 249, 255, 0.3);
  background: rgba(238, 249, 255, 0.2);
  backdrop-filter: blur(2px);
  white-space: normal;
  flex-shrink: 0;
  @media (max-width: 780px) {
    width: 100%;
    /* margin-right: 20px; */
  }
`;
const Title = styled.text`
  color: var(--Colors-Brand-Gray-300, #d1d5db);

  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.48px;
  text-align: start;
  @media (max-width: 780px) {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.4px;
  }
`;
const Desc = styled.text`
  color: var(--colors-system-base-400-darkgray, #989898);

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.4px;
  text-align: start;
  @media (max-width: 780px) {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.36px;
  }
`;
const AuthorName = styled.text`
  color: var(--colors-system-base-50-white, #fff);

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  @media (max-width: 780px) {
    font-size: 12px;
    line-height: 20px;
  }
`;
