import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 425,
      md: 1220,
      lg: 1440,
      xl: 1920,
      custom: 1440,
    },
  },
});

export const Fontsize = {
  DisplayLarge: 52,
  DisplaySmall: 44,
  H1Desktop: 40,
  H1Mobile: 36,
  H2Desktop: 36,
  H2Mobile: 32,
  H3Desktop: 32,
  H3Mobile: 28,
  H4Desktop: 28,
  H4Mobile: 24,
  H5Desktop: 24,
  H5Mobile: 20,
  H6Desktop: 20,
  H6Mobile: 18,
  ParagraphLarge: 18,
  ParagraphMedium: 16,
  ParagraphSmall: 14,
  ParagraphXSmall: 12,
  ParagraphOverlineLarge: 14,
  ParagraphOverlineSmall: 12,
  LabelLarge: 16,
  LabelMedium: 14,
  LabelSmall: 12,
  LabelXSmall: 10,
};

export const palette = {
  Primary: {
    50: "#EEF9FF",

    100: "#DAF1FF",

    200: "#BDE8FF",

    300: "#8FDBFF",

    400: "#5BC5FF",

    500: "#34A8FD",

    600: "#1E8AF2",

    700: "#1776E5",

    800: "#195CB4",

    900: "#1A4F8E",

    950: "#153156",
  },

  Secondary: {
    50: "#FDF4EF",

    100: "#FAE6DA",

    200: "#F5CAB3",

    300: "#EEA683",

    400: "#E67851",

    500: "#E2633F",

    600: "#D14025",

    700: "#AE2E20",

    800: "#8B2821",

    900: "#70241E",

    950: "#3C0F0E",
  },

  Tertiary: {
    50: "#EFFAF3",

    100: "#D8F3E1",

    200: "#B4E6C8",

    300: "#83D2A7",

    400: "#4FB882",

    500: "#30A76E",

    600: "#1E7D52",

    700: "#186443",

    800: "#155036",

    900: "#12422E",

    950: "#09251A",
  },

  Gray: {
    50: "#F9FAFB",

    100: "#F9FAFB",

    200: "#E5E7EB",

    300: "#D1D5DB",

    400: "#9CA3AF",

    500: "#6B7280",

    600: "#4B5563",

    700: "#374151",

    800: "#1F2937",

    900: "#111827",

    950: "#030712",
  },

  Base: {
    50: "#FFFFFF",

    100: "#EFEFEF",

    200: "#DCDCDC",

    300: "#BDBDBD",

    400: "#989898",

    500: "#7C7C7C",

    600: "#656565",

    700: "#525252",

    800: "#464646",

    900: "#3D3D3D",

    950: "#292929",
  },

  Success: {
    50: "#F0FDF5",

    100: "#DCFCE8",

    200: "#BBF7D1",

    300: "#86EFAD",

    400: "#4ADE80",

    500: "#22C55E",

    600: "#16A34A",

    700: "#15803C",

    800: "#166533",

    900: "#14532B",

    950: "#052E14",
  },

  Warning: {
    50: "#FFF8EB",

    100: "#FEEAC7",

    200: "#FDD28A",

    300: "#FCBB4D",

    400: "#FBAB24",

    500: "#F59E0B",

    600: "#D98B06",

    700: "#B47409",

    800: "#92610E",

    900: "#78510F",

    950: "#452C03",
  },

  Danger: {
    50: "#FEF2F2",

    100: "#FEE2E2",

    200: "#FECACA",

    300: "#FCA5A5",

    400: "#F87171",

    500: "#EF4444",

    600: "#DC2626",

    700: "#B91C1C",

    800: "#991B1B",

    900: "#7F1D1D",

    950: "#450A0A",
  },
};
