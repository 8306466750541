import "./App.css";
import ResponsiveAppBar from "./components/ResponsiveNavbar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./styles/globalStyle";
import Home from "./screens/Home";
import Terms from "./screens/Terms";
import PoliticUsage from "./screens/PoliticUsage";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import CookiePolicy from "./screens/CookiePolicy";
import Footer from "./containers/Footer";
import Projects from "./screens/Projects";
import AboutUs from "./screens/AboutUs";
import ContactUs from "./screens/ContactUs";
import Services from "./screens/Services";
import ProjectDetails from "./screens/ProjectDetails";

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <div class="container">
          <Router>
            <ResponsiveAppBar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/services" element={<Services />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/Project/:id" element={<ProjectDetails />} />
              <Route path="/aboutUs" element={<AboutUs />} />
              <Route path="/contactUs" element={<ContactUs />} />
              <Route path="/terms&Conditions" element={<Terms />} />
              <Route path="/politicOfUsage" element={<PoliticUsage />} />
              <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
              <Route path="/cookiePolicy" element={<CookiePolicy />} />
            </Routes>
            <Footer />
          </Router>
        </div>
      </ThemeProvider>
    </div>
  );
}

export default App;
