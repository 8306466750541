import React, { useEffect, useState } from "react";
import SectionHeader from "../components/SectionHeader";
import styled, { keyframes } from "styled-components";
import { motion } from "framer-motion";
import LogosSection from "./LogosSection";
import { projects } from "../Constants/contentData";
import InfiniteMovingBande from "../components/InfiniteMovingBande";
import { useNavigate, useParams } from "react-router-dom";

function Projects({ withoutBanner }) {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const handleOpenProject = (projectId) => {
    navigate(`/project/${projectId}`);
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 1080px)");
    setIsMobile(mediaQuery.matches);
    const handleResize = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addEventListener("change", handleResize);

    return () => mediaQuery.removeEventListener("change", handleResize);
  }, []);

  return (
    <Wrapper>
      {!withoutBanner && (
        <HeaderWrapper>
          <SectionHeader title="Projets" label="Notre travail" />
        </HeaderWrapper>
      )}
      <ContentWrapper>
        {projects.map(
          ({ id, bagdeTxt, name, isAvailable, picturePath }, index) => (
            <ProjectWrapper key={index}>
              <ProjectPicture src={picturePath} alt="project logo" />
              {!isAvailable && (
                <>
                  <Overlay />
                  <InfiniteMovingBande />
                </>
              )}
              <Banner
                onClick={() => {
                  if (isAvailable && isMobile) {
                    handleOpenProject(id);
                  }
                }}
              >
                <TextWrapper>
                  {!isMobile && <Badge>{bagdeTxt}</Badge>}
                  <ProjectTitle>{name}</ProjectTitle>
                </TextWrapper>
                {isAvailable && !isMobile && (
                  <ProjectDetailsBtn onClick={() => handleOpenProject(id)}>
                    Voir le project
                  </ProjectDetailsBtn>
                )}
              </Banner>
            </ProjectWrapper>
          )
        )}
      </ContentWrapper>
    </Wrapper>
  );
}

export default Projects;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const HeaderWrapper = styled.div`
  width: 100%;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background: linear-gradient(180deg, #000 0%, rgba(238, 249, 255, 0) 100%); */
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 90%;
  gap: 24px;
  justify-content: space-between;
  align-items: stretch;
  @media (max-width: 780px) {
    flex-direction: column;
    gap: 16px;
  }
`;
const ProjectWrapper = styled.div`
  position: relative;
  border: 1px solid rgba(238, 249, 255, 0.4);
  display: flex;
  flex-grow: 1;
  width: 48%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
  border-radius: 24px;
  background: var(--Colors-Brand-Gray-800, #1f2937);
  overflow: hidden;
  @media (max-width: 780px) {
    width: 100%;
  }
`;

const ProjectPicture = styled.img`
  width: 100%;
`;
const Overlay = styled.img`
  border-radius: 8px;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(41, 41, 41, 0.6);
  border-radius: 24px;
  backdrop-filter: blur(2.5px);
`;
const Banner = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  bottom: 0%;
  width: 100%;
  padding: 24px;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  margin-top: -150px;
  border-radius: 24px;
  border-top: 1px solid rgba(238, 249, 255, 0.3);
  border-right: 1px solid rgba(238, 249, 255, 0.3);
  border-left: 1px solid rgba(238, 249, 255, 0.3);
  background: linear-gradient(
    180deg,
    rgba(238, 249, 255, 0.15) 0%,
    rgba(238, 249, 255, 0) 100%
  );
  backdrop-filter: blur(7.150000095367432px);
`;

const Badge = styled.text`
  padding: 8px;
  color: var(--colors-system-base-50-white, #fff);
  border-radius: 72px;
  border: 1px solid rgba(238, 249, 255, 0.4);
  background: var(--Colors-Brand-Gray-800, #1f2937);
  text-align: center;

  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
`;
const ProjectTitle = styled.text`
  color: var(--Colors-Brand-Primary-100, #daf1ff);

  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 125% */
  letter-spacing: -0.64px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 14px;
`;

const ProjectDetailsBtn = styled.button`
  display: flex;
  align-items: center;
  gap: 6px;
  color: #34a8fd;
  text-align: center;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  border-radius: var(--Border-Radius-M, 8px);
  border: 1px solid #34a8fd;
  background-color: #fff;
  padding: 16px;
`;
