import React from "react";
import styled from "styled-components";
import { palette } from "../styles/globalStyle";
import { PaperPlaneTilt } from "@phosphor-icons/react";
import { experience } from "../Constants/contentData";
import LogosSection from "../containers/LogosSection";
import ProjectStepsSection from "../containers/ProjectStepsSection";
import ContactUsSection from "../containers/ContactUsSection";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

function AboutUs() {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>
          À Propos de KIAM TECH - Votre Partenaire en Solutions Digitales
        </title>
        <meta
          name="description"
          content="En savoir plus sur KIAMTECH, notre équipe, notre vision et notre mission. Nous sommes des experts en développement d'applications mobiles et de sites web."
        />
      </Helmet>
      <Wrapper>
        <ContentWrapper>
          <Image src="/assets/images/bgSquare.webp" />
          <Gradient />
          <LeftWrapper>
            <Title>Propulsons les entreprises grâce à l'innovation</Title>
            <Quote>
              “Nous aidons les entreprises à atteindre leurs objectifs grâce à
              des solutions digitales innovantes et centrées sur les résultats.”
            </Quote>
            <Desc>
              Kiam Tech est né de la conviction que la technologie peut être un
              puissant levier de croissance pour les entreprises. Nos
              fondateurs, forts de leur expertise en développement et [mention
              other founding skillsets], ont créé l'agence en [year] pour
              accompagner les organisations dans leur transformation numérique.
            </Desc>
            <ContactButton onClick={() => navigate("/contactUs")}>
              Envoyer
              <PaperPlaneTilt
                size={16}
                color={palette.Primary[500]}
                weight="duotone"
              />
            </ContactButton>
          </LeftWrapper>
        </ContentWrapper>
      </Wrapper>
      <StatisticsWrapper>
        {experience.map((exp, index) => (
          <StatisticsWrapperElement>
            {/* <motion.div>+ {rounded}</motion.div> */}
            <HeaderElementTitle>+ {exp.value}</HeaderElementTitle>
            <HeaderElementDesc>{exp.label}</HeaderElementDesc>
          </StatisticsWrapperElement>
        ))}
      </StatisticsWrapper>
      <LogosSection />
      <ProjectStepsSection />
      <ContactUsSection />
    </>
  );
}

export default AboutUs;

const Wrapper = styled.div`
  margin-top: 30px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid rgba(238, 249, 255, 0.6);
  border-radius: 32px 32px 0px 0px;
  /* background: linear-gradient(
    to bottom,
    rgba(238, 249, 255, 0.3),
    rgba(238, 249, 255, 0)
  ); */
  overflow: hidden;
`;
const ContentWrapper = styled.div`
  padding: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 32px 32px 0px 0px;
  background-image: url("/assets/images/logoBg.webp");
  /* background: linear-gradient(180deg, rgba(41, 41, 41, 0) 62.47%, #292929 100%),
    url("/assets/images/logoBg.webp") lightgray 0px -320.25px / 119.306% 169.094%
      no-repeat; */
  background-size: cover;
  background-position: right;
  aspect-ratio: 1.8897;
  @media (max-width: 780px) {
    position: inherit;
    aspect-ratio: 1;
    background-image: inherit;
    padding: 0;
    flex-direction: column;
  }
`;
const LeftWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: start;
  @media (max-width: 780px) {
    width: 100%;
    padding: 0 24px;
  }
`;

const Image = styled.img`
  display: none;
  width: 100%;
  @media (max-width: 780px) {
    display: block;
  }
`;
const Gradient = styled.div`
  position: absolute;
  width: 100%;
  height: 200px;
  background: linear-gradient(to top, #292929, transparent);
  bottom: 0;
  left: 0;
  right: 0;
  @media (max-width: 780px) {
    position: inherit;
    background: linear-gradient(to bottom, #000, transparent);
    height: 90px;
  }
`;
const Title = styled.text`
  color: var(--colors-system-base-50-white, #fff);
  font-size: 44px;
  font-style: normal;
  font-weight: 900;
  line-height: 48px;
  letter-spacing: -0.88px;
  @media (max-width: 780px) {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.64px;
  }
`;

const Quote = styled.text`
  margin-top: 24px;
  color: var(--Colors-Brand-Tertiary-300, #83d2a7);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  @media (max-width: 780px) {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1px;
  }
`;
const Desc = styled.text`
  margin-top: 24px;
  color: var(--colors-system-base-400-darkgray, #989898);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  @media (max-width: 780px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
const ContactButton = styled.button`
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  color: #34a8fd;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  border-radius: var(--Border-Radius-M, 8px);
  border: 1px solid #34a8fd;
  background-color: #fff;
  padding: 16px 60px;
  z-index: 1;
  @media (max-width: 780px) {
    width: 100%;
  }
`;
const StatisticsWrapperElement = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid rgba(52, 168, 253, 0.3);
  background: rgba(26, 79, 142, 0.3);
  backdrop-filter: blur(2px);
`;
const StatisticsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  margin-top: 90px;
  padding: 0 60px;
  @media (max-width: 550px) {
    flex-direction: column;
    gap: 16px;
  }
`;

const HeaderElementTitle = styled.text`
  color: var(--Colors-Brand-Primary-500, #34a8fd);

  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: 48px;
  letter-spacing: -0.8px;
  text-align: start;
  @media (max-width: 780px) {
    ont-size: 36px;
    line-height: 44px;
    letter-spacing: -0.72px;
  }
`;
const HeaderElementDesc = styled.text`
  color: var(--Colors-Brand-Gray-50, #f9fafb);

  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.48px;
  text-align: start;
`;
