import React, { useEffect, useState } from "react";
import SectionHeader from "../components/SectionHeader";
import styled, { keyframes } from "styled-components";
import { motion } from "framer-motion";
import { services } from "../Constants/contentData";
import HorizontalScrollContainer from "../components/HorizontalScrollView";

function ServicesSection() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
    const handleResize = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addEventListener("change", handleResize);

    return () => mediaQuery.removeEventListener("change", handleResize);
  }, []);
  return (
    <Wrapper>
      <SectionHeader label="services" title="Nos services proposés" />
      {isMobile ? (
        <HorizontalScrollContainer>
          {services.map((service, index) => (
            <Service>
              <img src={service.iconPath} alt="icon" />
              <Title>{service.title}</Title>
              <Desc>{service.desc}</Desc>
            </Service>
          ))}
        </HorizontalScrollContainer>
      ) : (
        <ServicesWrapper>
          {services.map((service, index) => (
            <Service>
              <img src={service.iconPath} alt="icon" />
              <Title>{service.title}</Title>
              <Desc>{service.desc}</Desc>
            </Service>
          ))}
        </ServicesWrapper>
      )}
    </Wrapper>
  );
}

export default ServicesSection;

const Wrapper = styled.div`
  width: 100%;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ServicesWrapper = styled.div`
  width: 90%;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  margin-top: 30px;
`;
const Service = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  /* justify-content: start; */
  align-items: flex-start;
  width: 416px;
  padding: 32px;
  gap: 28px;
  border-radius: 32px;
  border: 2px solid rgba(238, 249, 255, 0.1);
  backdrop-filter: blur(2px);
  white-space: normal;
  @media (max-width: 780px) {
    width: 100%;
    /* margin-right: 20px; */
  }
`;

const Title = styled.text`
  color: var(--colors-system-base-50-white, #fff);

  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.64px;
  text-align: start;
  white-space: normal;
  @media (max-width: 780px) {
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.56px;
  }
`;
const Desc = styled.text`
  color: var(--colors-system-base-400-darkgray, #989898);

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.4px;
  text-align: start;
  white-space: normal;
  @media (max-width: 780px) {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.36px;
  }
`;
