import * as yup from "yup";

const emailRegex =
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|fr|kr|net|us|info|biz|me|name|cc|org|tv|ws|mobi|de|am|fm|ca|bz|com.bz|net.bz|es|co|se|la)$/;

const emailSchema = yup.object().shape({
  name: yup.string().required("Ce champ est requis"),
  phone: yup
    .string()
    .matches(
      /^0\d{1}\s*\d{2}\s*\d{2}\s*\d{2}\s*\d{2}$/,
      "Merci de saisir un numéro de téléphone valide, composé de 10 chiffres et commençant par 0"
    )
    .required("Le numéro de téléphone est requis"),
  emailBody: yup.string().required("Ce champ est requis"),
  email: yup
    .string()
    .trim("Ce champ ne peut pas inclure d'espaces en début et en fin.")
    .strict(true)
    .required("Ce champ est requis")
    .matches(emailRegex, "Veuillez entrer une adresse e-mail valide"),
});

export default emailSchema;
