import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import styled from "styled-components";
import { PaperPlaneTilt, List } from "@phosphor-icons/react";
import { useNavigate, NavLink } from "react-router-dom";
import { palette } from "../styles/globalStyle";
import { pages } from "../Constants/contentData";

function ResponsiveAppBar() {
  const [isMenuOpened, setIsMenuOpened] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 1220px)");
    setIsMobile(mediaQuery.matches);
    const handleResize = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addEventListener("change", handleResize);

    return () => mediaQuery.removeEventListener("change", handleResize);
  }, []);

  const handleOpenNavMenu = () => {
    setIsMenuOpened((prev) => !prev);
  };

  return (
    <AppBar
      position="static"
      color="transparent"
      style={{
        padding: "16px",
        borderBottomLeftRadius: isMobile ? "16px" : "32px",
        borderBottomRightRadius: isMobile ? "16px" : "32px",
        borderRight: "1px solid rgba(238, 249, 255, 0.30)",
        borderBottom: "1px solid rgba(238, 249, 255, 0.30)",
        borderLeft: "1px solid rgba(238, 249, 255, 0.30)",
        backgroundColor: "rgba(238, 249, 255, 0.20)",
        backdropFilter: "blur(2px)",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
          <Box
            sx={{
              display: "flex",
              mr: 1,
              alignItems: "center",
            }}
          >
            <img
              onClick={() => navigate("/")}
              src={"/assets/logos/logo.svg"}
              alt="website logo svg"
              style={{ width: isMobile ? "141px" : null }}
            />
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <List
                color={palette.Primary[500]}
                size={44}
                weight="duotone"
                style={{}}
              />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <NavBtnWrapper>
              {pages.map((page) => (
                <NavLink
                  key={page}
                  to={page.path}
                  activeStyle={{ color: "red" }}
                  style={({ isActive, isPending, isTransitioning }) => {
                    return {
                      marginLeft: "24px",
                      marginRight: "24px",
                      textDecoration: "none",
                      color: isActive ? palette.Primary[500] : palette.Base[50],
                      fontFamily: "Satoshi",
                      fontWeight: 400,
                      fontSize: 18,
                      backgroundColor: "transparent",
                    };
                  }}
                >
                  {page.label}
                </NavLink>
              ))}
            </NavBtnWrapper>
          </Box>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <StyledButton onClick={() => navigate("/contactUs")}>
              Contactez-nous
              <PaperPlaneTilt color="#34a8fd" weight="duotone" size={16} />
            </StyledButton>
          </Box>
        </Toolbar>
        {isMenuOpened && (
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              flexWrap: "wrap",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <NavBtnWrapper>
              {pages.map((page) => (
                <NavLink
                  onClick={() => setIsMenuOpened(false)}
                  key={page}
                  to={page.path}
                  activeStyle={{ color: "red" }}
                  style={({ isActive, isPending, isTransitioning }) => {
                    return {
                      marginLeft: "21px",
                      marginRight: "21px",
                      textDecoration: "none",
                      color: isActive ? palette.Primary[500] : palette.Base[50],
                      fontWeight: 400,
                      fontSize: "18px",
                      lineHeight: "38px",
                      backgroundColor: "transparent",
                    };
                  }}
                >
                  {page.label}
                </NavLink>
              ))}
            </NavBtnWrapper>
            <StyledButtonMobile
              primary
              onClick={() => {
                navigate("/contactUs");
                setIsMenuOpened(false);
              }}
            >
              Contactez-nous
              <PaperPlaneTilt
                size={16}
                color={palette.Primary[500]}
                weight="duotone"
              />
            </StyledButtonMobile>
          </Box>
        )}
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;
const StyledButton = styled.button`
  display: flex;
  align-items: center;
  gap: 6px;
  color: #34a8fd;
  text-align: center;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  border-radius: var(--Border-Radius-M, 8px);
  border: 1px solid #34a8fd;
  background-color: #fff;
  padding: 16px;
`;
const StyledButtonMobile = styled.button`
  display: flex;
  align-items: center;
  gap: 6px;
  color: #34a8fd;
  text-align: center;
  justify-content: center;
  margin-top: 24px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  border-radius: var(--Border-Radius-M, 8px);
  border: 1px solid #34a8fd;
  background-color: #fff;
  padding: 16px;
`;

const NavBtnWrapper = styled.div`
  border-radius: var(--Border-Radius-none, 12px);
  opacity: 0.6;
  background: var(--colors-system-base-950-black, #292929);
  backdrop-filter: blur(304px);
  display: flex;
  padding: 16px;
  @media (max-width: 780px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;
