import React from "react";
import styled from "styled-components";
import { EnvelopeSimpleOpen } from "@phosphor-icons/react";
import { palette } from "../styles/globalStyle";

function ContactWidget({ contact }) {
  return (
    <>
      <ContactBloc>
        <EnvelopeSimpleOpen
          color={palette.Base[50]}
          weight="duotone"
          size={48}
        />
        <ContactLabel>{contact.label}</ContactLabel>
        <Divider />
        <ContactValue>{contact.value}</ContactValue>
      </ContactBloc>
    </>
  );
}

export default ContactWidget;

const ContactBloc = styled.div`
  display: flex;
  flex-grow: 1;
  width: 404px;
  padding: 32px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 28px;
  border-radius: 16px;
  border: 2px solid rgba(238, 249, 255, 0.1);
  backdrop-filter: blur(2px);
`;
const ContactLabel = styled.text`
  color: var(--Colors-Brand-Primary-600, #1e8af2);

  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-align: start;
`;
const ContactValue = styled.text`
  color: var(--colors-system-base-50-white, #fff);

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: start;
`;
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #eef9ff;
`;
