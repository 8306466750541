import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { palette } from "../styles/globalStyle";
import ComingSoon from "../components/ComingSoon";
import ContactUsSection from "../containers/ContactUsSection";
import { contactDetails } from "../Constants/contentData";
import { EnvelopeSimpleOpen, PaperPlaneTilt } from "@phosphor-icons/react";
import ContactWidget from "../components/ContactWidget";
import { Formik } from "formik";
import emailSchema from "../models/emailSchema";
import LogosSection from "../containers/LogosSection";
import { config } from "../config/config";
import axios from "axios";
import Toast from "../components/Toast";
import { Helmet } from "react-helmet";

function ContactUs() {
  const [isMobile, setIsMobile] = useState(false);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(null);
  const [toastVariant, setToastVariant] = useState(null);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 780px)");
    setIsMobile(mediaQuery.matches);
    const handleResize = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addEventListener("change", handleResize);

    return () => mediaQuery.removeEventListener("change", handleResize);
  }, []);
  const contacts = [
    {
      label: "Email",
      icon: (
        <EnvelopeSimpleOpen
          color={palette.Base[50]}
          weight="duotone"
          size={48}
        />
      ),
      value: contactDetails.email,
    },
    {
      label: "Numéro de téléphone",
      icon: (
        <EnvelopeSimpleOpen
          color={palette.Base[50]}
          weight="duotone"
          size={48}
        />
      ),
      value: contactDetails.phone,
    },
    {
      label: "Localisation",
      icon: (
        <EnvelopeSimpleOpen
          color={palette.Base[50]}
          weight="duotone"
          size={48}
        />
      ),
      value: contactDetails.adress,
    },
  ];

  const initialValues = { name: "", phone: "", email: "", emailBody: "" };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const emailData = {
      recipient: "contact@kiamtech.com",
      subject: `${values.name} - from contact us page`,
      customer_email: values.email,
      content: values.emailBody,
      username: values.name,
    };
    try {
      const res = await axios.post(`/contact-us`, emailData);
      console.log("res :", res);
      if (res.status === 200) {
        resetForm();
        setMessageInfo("email envoyé avec succés");
        setToastVariant("success");
        setIsToastOpen(true);
      }
    } catch (error) {
      setMessageInfo("L'e-mail n'a pas été envoyé");
      setToastVariant("error");
      setIsToastOpen(true);
      console.log("erreur lors de l'envoi de l email :", error);
    }
    setSubmitting(false);
  };
  return (
    <Wrapper>
      <Helmet>
        <title>Contactez-nous - KIAMTECH</title>
        <meta
          name="description"
          content="Contactez KIAM TECH pour discuter de vos projets digitales."
        />
      </Helmet>
      <ContentWrapper>
        {!isMobile && (
          <WrapperLeft>
            {contacts.map((contact, index) => (
              <ContactWidget contact={contact} key={index} />
            ))}
          </WrapperLeft>
        )}
        <WrapperRight>
          <Title>Vous avez un projet ?</Title>
          <Desc>Collaborons pour créer quelque chose d'exceptionnel</Desc>
          <Formik
            initialValues={initialValues}
            validationSchema={emailSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              handleSubmit(values, { setSubmitting, resetForm });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <ContactInfoWrapper>
                  <InputWrapper>
                    <TextInput
                      type="text"
                      name="name"
                      placeholder="Nom"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      style={{
                        borderColor:
                          errors.name && touched.name ? "#e2633f" : "#989898",
                      }}
                    />
                    <ErrorTxt>
                      {errors.name && touched.name && errors.name}
                    </ErrorTxt>
                  </InputWrapper>
                  <InputWrapper>
                    <TextInput
                      type="text"
                      name="phone"
                      placeholder="Numéro de téléphone"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={10}
                      value={values.phone}
                      style={{
                        borderColor:
                          errors.phone && touched.phone ? "#e2633f" : "#989898",
                      }}
                    />

                    <ErrorTxt>
                      {errors.phone && touched.phone && errors.phone}
                    </ErrorTxt>
                  </InputWrapper>
                </ContactInfoWrapper>
                <InputWrapper>
                  <TextInput
                    type="email"
                    name="email"
                    placeholder="Email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    style={{
                      borderColor:
                        errors.email && touched.email ? "#e2633f" : "#989898",
                    }}
                  />
                  <ErrorTxt>
                    {errors.email && touched.email && errors.email}
                  </ErrorTxt>
                </InputWrapper>
                <InputWrapper>
                  <Textarea
                    type="text"
                    name="emailBody"
                    placeholder="Description du projet"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.emailBody}
                    rows="10"
                    style={{
                      borderColor:
                        errors.emailBody && touched.emailBody
                          ? "#e2633f"
                          : "#989898",
                    }}
                  />
                  <ErrorTxt>
                    {errors.emailBody && touched.emailBody && errors.emailBody}
                  </ErrorTxt>
                </InputWrapper>
                <ContactButton type="submit" disabled={isSubmitting}>
                  Envoyer
                  <PaperPlaneTilt
                    size={16}
                    color={palette.Primary[500]}
                    weight="duotone"
                  />
                </ContactButton>
              </Form>
            )}
          </Formik>
        </WrapperRight>
      </ContentWrapper>
      <LogosSection />
      <Toast
        isToastOpen={isToastOpen}
        setIsToastOpen={setIsToastOpen}
        variant={toastVariant}
        message={messageInfo}
      />
    </Wrapper>
  );
}

export default ContactUs;

const Wrapper = styled.div`
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 32px 32px 0px 0px;
  border-top: 1px solid rgba(238, 249, 255, 0.3);
  border-left: 1px solid rgba(238, 249, 255, 0.3);
  border-right: 1px solid rgba(238, 249, 255, 0.3);
  overflow: hidden;
`;
const ContentWrapper = styled.div`
  margin: 80px;
  width: 90%;
  display: flex;
  align-items: stretch;
  gap: 20px;
  justify-content: space-between;
  margin: 80px;
`;
const WrapperLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 34px;
`;
const WrapperRight = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 16px;
  @media (max-width: 780px) {
    width: 100%;
  }
`;
const ContactInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  gap: 16px;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;
const Form = styled.form`
  width: 100%;
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 24px;
`;
const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;
`;

const Title = styled.text`
  color: var(--colors-system-base-50-white, #fff);

  font-size: 44px;
  font-style: normal;
  font-weight: 900;
  line-height: 48px;
  letter-spacing: -0.88px;
  text-align: start;
  @media (max-width: 780px) {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.64px;
  }
`;
const Desc = styled.text`
  color: var(--colors-system-base-400-darkgray, #989898);

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  text-align: start;
  @media (max-width: 780px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
const TextInput = styled.input`
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex-grow: 1;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--colors-system-base-50-white, #fff);
  background-color: transparent;
  color: var(--colors-system-base-400-darkgray, #989898);

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  @media (max-width: 780px) {
  }
`;

const Textarea = styled.textarea`
  display: flex;
  width: 410px;
  padding: 16px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid var(--colors-system-base-50-white, #fff);
  background-color: transparent;
  color: var(--colors-system-base-400-darkgray, #989898);

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  @media (max-width: 780px) {
  }
`;
const ErrorTxt = styled.text`
  color: var(--colors-system-base-400-darkgray, #e2633f);

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  @media (max-width: 780px) {
  }
`;

const ContactButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  color: #34a8fd;
  text-align: center;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  border-radius: var(--Border-Radius-M, 8px);
  border: 1px solid #34a8fd;
  background-color: #fff;
  padding: 16px;
  align-self: self-end;
  @media (max-width: 780px) {
    width: 100%;
  }
`;
