import React from "react";
import styled, { keyframes } from "styled-components";
import { PaperPlaneTilt, X, List } from "@phosphor-icons/react";
import { palette } from "../styles/globalStyle";
import { AuroraBackground } from "../components/Aurora-backgound";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { Highlight } from "../components/HeroHighlight";

function HeroSection() {
  const navigate = useNavigate();
  return (
    <AuroraBackground>
      <Wrapper>
        <Arrow src={"/assets/arrow.svg"} />
        <MainTitle>
          Transformez <Highlight isBlue> votre entreprise </Highlight> avec des
          <br />
          <Highlight> solutions digitales </Highlight> sur mesure
        </MainTitle>
        <Desc>
          Nous concevons et développons des applications mobiles de pointe, des
          plateformes SaaS et des sites web à fort taux de conversion qui
          génèrent des résultats concrets.
        </Desc>
        <BtnWrapper>
          <ContactButton onClick={() => navigate("/contactUs")}>
            Contactez-nous
            <PaperPlaneTilt
              size={16}
              color={palette.Primary[500]}
              weight="duotone"
            />
          </ContactButton>
          <ServicesButton onClick={() => navigate("/services")}>
            Voir nos services
          </ServicesButton>
        </BtnWrapper>
      </Wrapper>
    </AuroraBackground>
  );
}

export default HeroSection;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  min-height: 900px;
  margin-top: -60px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 780px) {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding-bottom: 100px;
  }
`;
const BtnWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin-top: 30px;
  @media (max-width: 780px) {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
`;

const MainTitle = styled.text`
  color: white;
  text-align: center;

  font-size: 52px;
  font-style: normal;
  font-weight: 900;
  line-height: 150%;
  letter-spacing: -1.04px;
  width: 70%;
  @media (max-width: 780px) {
    font-size: 36px;
    font-style: normal;
    font-weight: 900;
    letter-spacing: -0.72px;
  }
`;
const Desc = styled.text`
  margin-top: 18px;
  color: #989898;
  text-align: center;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  width: 70%;
`;

const ContactButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  color: #34a8fd;
  text-align: center;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  border-radius: var(--Border-Radius-M, 8px);
  border: 1px solid #34a8fd;
  background-color: #fff;
  padding: 16px 60px;
  @media (max-width: 780px) {
    width: 90%;
  }
`;
const ServicesButton = styled.button`
  color: #e2633f;
  text-align: center;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  border-radius: 8px;
  border: 1px solid #fae6da;
  background-color: #fff;
  padding: 16px;
  @media (max-width: 780px) {
    width: 90%;
  }
`;

const Arrow = styled.img`
  align-self: self-start;
  margin-left: 15%;
`;
