import React, { useEffect } from "react";
import SectionHeader from "../components/SectionHeader";
import styled from "styled-components";
import { palette } from "../styles/globalStyle";
import { EnvelopeSimpleOpen, PaperPlaneTilt } from "@phosphor-icons/react";
import { experience } from "../Constants/contentData";
import { motion, useMotionValue, useTransform, animate } from "framer-motion";
import { useNavigate } from "react-router-dom";

function AboutUsSection() {
  const navigate = useNavigate();
  // const count = useMotionValue(0);
  // const rounded = useTransform(count, (latest) => Math.round(latest));

  // useEffect(() => {
  //   const controls = animate(count, 100);

  //   return controls.stop;
  // }, []);
  return (
    <Wrapper>
      <SectionHeader
        label="A propos de nous"
        title="Propulsons les entreprises grâce à l'innovation"
      />
      <Content>
        <Image />
        <ContentWrapper>
          <ContentHeader>
            {experience.map((exp, index) => (
              <ContentHeaderElement
                style={{ flexGrow: index === 0 ? 1 : null }}
              >
                {/* <motion.div>+ {rounded}</motion.div> */}
                <HeaderElementTitle>+ {exp.value}</HeaderElementTitle>
                <HeaderElementDesc>{exp.label}</HeaderElementDesc>
              </ContentHeaderElement>
            ))}
          </ContentHeader>
          <Desc>
            Chez Kiam Tech, notre mission transcende la simple création de sites
            web et d'applications mobiles.
            <br />
            Nous sommes animés par une passion profonde pour le numérique et une
            volonté inébranlable d'innover.
            <br />
            Notre objectif ? Transformer votre vision en réalité digitale,
            propulsant votre entreprise vers de nouveaux sommets.
            <br />
            <br />
            Notre équipe va au-delà du simple développement, nous élaborons des
            solutions personnalisées qui répondent précisément à vos besoins.
            <br />
            Chaque projet est une aventure, où votre succès devient le nôtre.
            <br />
            <br />
            Chez Kiam Tech, nous croyons à l'importance de l'écoute et de
            l'adaptation.
            <br />
            Nous plongeons au cœur de votre industrie pour en comprendre chaque
            nuance, chaque défi.
            <br />
            Notre méthodologie agile, combinée à une veille technologique
            constante, nous permet de vous offrir des solutions avant-gardistes,
            efficaces et évolutives.
            <br />
            <br />
            L'innovation est au cœur de tout ce que nous faisons, mais notre
            véritable différence réside dans nos valeurs fondamentales :
            transparence, intégrité et engagement.
            <br />
            Nous construisons des partenariats solides, basés sur la confiance
            et le respect mutuel.
            <br />
            <br />
            Vous recherchez un partenaire technologique qui va au-delà de la
            simple exécution ?<br />
            Qui comprend votre vision et se bat à vos côtés pour la réaliser ?
            <br />
            Contactez Kiam Tech dès aujourd'hui. Ensemble, façonnons l'avenir de
            votre entreprise.
            <br />
            Donnons vie à vos idées.
          </Desc>
          <BtnWrapper>
            <ContactButton onClick={() => navigate("/aboutUs")}>
              En savoir plus
            </ContactButton>
            <ServicesButton onClick={() => navigate("/contactUs")}>
              Contactez-nous{" "}
              <PaperPlaneTilt
                size={16}
                color={palette.Secondary[500]}
                weight="duotone"
              />
            </ServicesButton>
          </BtnWrapper>
        </ContentWrapper>
      </Content>
    </Wrapper>
  );
}

export default AboutUsSection;

const Wrapper = styled.div`
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Content = styled.div`
  margin-top: 30px;
  width: 90%;
  display: flex;
  gap: 16px;
  align-items: stretch;
  @media (max-width: 780px) {
    flex-direction: column;
    gap: 16px;
    align-items: start;
  }
`;
const Image = styled.div`
  width: 40%;
  background-image: url("/assets/images/aboutUsPic.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 30px;
  @media (max-width: 780px) {
    width: 100%;
    aspect-ratio: 1;
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  width: 716px;
  padding: 24px;
  flex-direction: column;
  gap: 24px;
  border-radius: 32px;
  border-top: 1px solid rgba(238, 249, 255, 0.3);
  border-right: 1px solid rgba(238, 249, 255, 0.3);
  border-left: 1px solid rgba(238, 249, 255, 0.3);
  background: linear-gradient(
    180deg,
    rgba(253, 244, 239, 0.15) 0%,
    rgba(250, 230, 218, 0.1) 100%
  );
  @media (max-width: 780px) {
    width: 100%;
  }
`;
const ContentHeaderElement = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid rgba(52, 168, 253, 0.3);
  background: rgba(26, 79, 142, 0.3);
  backdrop-filter: blur(2px);
`;
const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  @media (max-width: 550px) {
    flex-direction: column;
    gap: 16px;
  }
`;

const HeaderElementTitle = styled.text`
  color: var(--Colors-Brand-Primary-500, #34a8fd);

  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: 48px;
  letter-spacing: -0.8px;
  text-align: start;
  @media (max-width: 780px) {
    ont-size: 36px;
    line-height: 44px;
    letter-spacing: -0.72px;
  }
`;
const HeaderElementDesc = styled.text`
  color: var(--Colors-Brand-Gray-50, #f9fafb);

  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.48px;
  text-align: start;
`;
const Desc = styled.text`
  color: var(--colors-system-base-50-white, #fff);

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  text-align: start;
`;

const BtnWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin-top: 30px;
  @media (max-width: 780px) {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: stretch;
  }
`;

const ContactButton = styled.button`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #34a8fd;
  text-align: center;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  border-radius: 8px;
  border: 1px solid #34a8fd;
  background-color: #fff;
  padding: 16px;
`;
const ServicesButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e2633f;
  text-align: center;

  font-size: 18px;
  font-style: normal;
  flex-grow: 1;
  font-weight: 500;
  line-height: 28px;
  border-radius: 8px;
  border: 1px solid #fae6da;
  background-color: #fff;
  padding: 16px;
  gap: 8px;
`;
