import React from "react";
import styled from "styled-components";
import { palette } from "../styles/globalStyle";
import ComingSoon from "../components/ComingSoon";
import ContactUsSection from "../containers/ContactUsSection";
import ProjectsSection from "../containers/ProjectsSection";
import LogosSection from "../containers/LogosSection";
import ReviewsSection from "../containers/ReviewsSection";
import { Helmet } from "react-helmet";

function Projects() {
  return (
    <Wrapper>
      <Helmet>
        <title>Projets Réalisés - KIAM TECH</title>
        <meta
          name="description"
          content="Explorez nos projets récents pour voir comment KIAMTECH a aidé des clients à réussir avec des applications mobiles, des sites web, et des solutions digitales personnalisées."
        />
      </Helmet>
      {/* <ComingSoon /> */}
      <Title>Projects</Title>
      <ProjectsSection withoutBanner />
      <LogosSection />
      {/* <ReviewsSection /> */}
      <ContactUsSection />
    </Wrapper>
  );
}

export default Projects;

const Wrapper = styled.div`
  margin-top: 30px;
  padding-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  border-radius: 32px 32px 0px 0px;
  border-top: 1px solid rgba(238, 249, 255, 0.3);
  border-left: 1px solid rgba(238, 249, 255, 0.3);
  border-right: 1px solid rgba(238, 249, 255, 0.3);
  overflow: hidden;
`;
const Title = styled.text`
  color: #fff;
  margin-left: 60px;
  margin-bottom: 80px;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: 48px;
  text-align: start;
  letter-spacing: -0.8px;

  @media (max-width: 780px) {
    font-size: 36px;
    font-style: normal;
    font-weight: 900;
    line-height: 44px; /* 122.222% */
    letter-spacing: -0.72px;
  }
`;
