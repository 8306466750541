export const pages = [
  { label: "Accueil", path: "/" },
  { label: "Services", path: "/services" },
  { label: "Projets", path: "/projects" },
  { label: "A propos de nous", path: "/aboutUs" },
  { label: "Contactez nous", path: "/contactUs" },
];

export const projects = [
  {
    id: 1,
    bagdeTxt: "Mobile App - Landing Page",
    picturePath: "/assets/images/project1_1.png",
    name: "Gérer Mon Permis",
    desc: `GérerMonPermis est l'application mobile incontournable pour tous les conducteurs français. Notre mission est simple : vous simplifier la gestion de vos amendes et de vos points de permis en vous offrant une solution intuitive et centralisée.
    Dites adieu au stress lié aux amendes et reprenez le contrôle de la route en toute sérénité. GérerMonPermis vous propose une multitude de fonctionnalités pour vous faciliter la vie :
    Consultation instantanée de votre solde de points pour rouler l'esprit tranquille.
    Ajout et organisation simplifiés de vos amendes en un seul endroit pour une meilleure gestion.
    Paiement sécurisé directement depuis l'application pour régler vos amendes rapidement et facilement.
    Suivi de l'état de vos contestations pour ne jamais perdre le fil.
    Fonctionnalité innovante "Véhicule" (à venir) pour centraliser les informations de vos véhicules et programmer des rappels d'entretien (vidange, contrôle technique...).
    
    GérerMonPermis, c'est bien plus qu'une simple application. C'est une équipe d'experts passionnés par la technologie et la conduite responsable qui s'engage à vous offrir une expérience optimale.`,
    isAvailable: true,
  },
  {
    id: 2,
    bagdeTxt: "Mobile App - Landing Page",
    picturePath: "/assets/images/gererMesVehicles.png",
    name: "Gérer Mes Vehicules",
    desc: `GérerMonPermis est l'application mobile incontournable pour tous les conducteurs français. Notre mission est simple : vous simplifier la gestion de vos amendes et de vos points de permis en vous offrant une solution intuitive et centralisée.
    Dites adieu au stress lié aux amendes et reprenez le contrôle de la route en toute sérénité. GérerMonPermis vous propose une multitude de fonctionnalités pour vous faciliter la vie :
    Consultation instantanée de votre solde de points pour rouler l'esprit tranquille.
    Ajout et organisation simplifiés de vos amendes en un seul endroit pour une meilleure gestion.
    Paiement sécurisé directement depuis l'application pour régler vos amendes rapidement et facilement.
    Suivi de l'état de vos contestations pour ne jamais perdre le fil.
    Fonctionnalité innovante "Véhicule" (à venir) pour centraliser les informations de vos véhicules et programmer des rappels d'entretien (vidange, contrôle technique...).
    
    GérerMonPermis, c'est bien plus qu'une simple application. C'est une équipe d'experts passionnés par la technologie et la conduite responsable qui s'engage à vous offrir une expérience optimale.`,
    isAvailable: false,
  },
];
// export const services = [
//   {
//     title: "Developpement d'application Web",
//     desc: "Nous concevons des applications Web modernes et sécurisées qui répondent aux besoins complexes de votre entreprise. Notre expertise en technologies front-end et back-end garantit des solutions robustes et évolutives.",
//     iconPath: "/assets/serviceIcon1.svg",
//   },
//   {
//     title: "Developpement d'application mobile",
//     desc: "Créez des expériences mobiles intuitives et performantes qui fidélisent vos utilisateurs. Nous concevons des applications natives et hybrides sur mesure avec React Native, Flutter, et d'autres technologies de pointe pour iOS et Android.",
//     iconPath: "/assets/serviceIcon2.svg",
//   },
//   {
//     title: "Developpement de site web",
//     desc: "Propulsez votre présence en ligne avec des sites web esthétiques et axés sur la conversion. Nous créons des expériences numériques qui génèrent des prospects et renforcent l'image de votre marque.",
//     iconPath: "/assets/serviceIcon3.svg",
//   },
//   {
//     title: "UX/UI Design",
//     desc: "Concevez des interfaces intuitives centrées sur vos utilisateurs. Notre expertise en UX/UI Design garantit des expériences fluides et engageantes qui améliorent la satisfaction et la conversion.",
//     iconPath: "/assets/serviceIcon4.svg",
//   },
//   {
//     title: "Consulting Informatique",
//     desc: "Bénéficiez d'une expertise stratégique pour orienter vos décisions technologiques. Nous vous accompagnons dans l'identification de solutions adaptées à vos enjeux commerciaux et opérationnels.",
//     iconPath: "/assets/serviceIcon5.svg",
//   },
//   {
//     title: "Move to DevOps Organisation",
//     desc: "Optimisez votre cycle de développement logiciel grâce à notre accompagnement dans la transition vers une organisation DevOps. Nous vous guidons dans la mise en place de pratiques collaboratives et d'outils d'automatisation.",
//     iconPath: "/assets/serviceIcon6.svg",
//   },
//   {
//     title: "GitOps approach",
//     desc: "Renforcez la fiabilité et la sécurité de vos déploiements grâce à l'approche GitOps. Nous vous aidons à implémenter des processus centrés sur Git, améliorant la traçabilité et la gestion de vos versions.",
//     iconPath: "/assets/serviceIcon7.svg",
//   },
//   {
//     title: "Hébergement & Infogérance",
//     desc: "Bénéficiez d'une expertise stratégique pour orienter vos décisions technologiques. Nous vous accompagnons dans l'identification de solutions adaptées à vos enjeux commerciaux et opérationnels.",
//     iconPath: "/assets/serviceIcon8.svg",
//   },
// ];
export const services = [
  {
    id: 1,
    title: "Développement d'applications Web",
    desc: "Notre équipe développe des applications Web sur mesure, combinant design moderne et sécurité. Nous utilisons les dernières technologies front-end et back-end pour offrir des solutions évolutives et performantes.",
    iconPath: "/assets/serviceIcon5.svg",
  },
  {
    id: 2,
    title: "Développement d'applications mobiles",
    desc: "Nous créons des expériences mobiles captivantes et fluides. En utilisant React Native, Flutter et d'autres technologies avancées, nous développons des applications natives et hybrides pour iOS et Android, optimisées pour l'engagement et la performance.",
    iconPath: "/assets/serviceIcon8.svg",
  },
  {
    id: 3,
    title: "Développement de sites web",
    desc: "Augmentez votre visibilité en ligne avec un site web professionnel, conçu pour convertir les visiteurs en clients. Nos sites sont optimisés pour le SEO, avec un design responsive et centrés sur l'expérience utilisateur.",
    iconPath: "/assets/serviceIcon2.svg",
  },
  {
    id: 4,
    title: "Design UX/UI",
    desc: "Créez des interfaces utilisateur attractives et fonctionnelles. Notre approche du design UX/UI est centrée sur l'utilisateur, pour des expériences digitales mémorables favorisant la conversion et la fidélisation.",
    iconPath: "/assets/serviceIcon6.svg",
  },
  {
    id: 5,
    title: "Consulting en informatique",
    desc: "Profitez de notre expertise pour guider vos choix technologiques. Nous offrons un conseil stratégique personnalisé, vous aidant à trouver les solutions technologiques adaptées à vos objectifs d'affaires.",
    iconPath: "/assets/serviceIcon4.svg",
  },
  {
    id: 6,
    title: "Adoption de l'organisation DevOps",
    desc: "Améliorez votre processus de développement logiciel en adoptant les principes DevOps. Nous facilitons votre transition vers une culture DevOps, favorisant la collaboration, l'automatisation et l'optimisation continue.",
    iconPath: "/assets/serviceIcon1.svg",
  },
  {
    id: 7,
    title: "Approche GitOps",
    desc: "Augmentez la fiabilité et la sécurité de vos déploiements avec GitOps. Nous mettons en œuvre des workflows basés sur Git pour une meilleure traçabilité et une gestion efficace des versions et configurations.",
    iconPath: "/assets/serviceIcon3.svg",
  },
  {
    id: 8,
    title: "Hébergement & Infogérance",
    desc: "Assurez la performance et la sécurité de vos applications avec nos solutions d'hébergement et d'infogérance. Nous offrons une gestion complète de vos infrastructures, avec un support technique réactif.",
    iconPath: "/assets/serviceIcon7.svg",
  },
];

export const experience = [
  {
    label: "Années d'expérience",
    value: 10,
  },
  {
    label: "Projets terminés",
    value: 48,
  },
  {
    label: "Clients satisfaits",
    value: 20,
  },
];

// export const projectSteps = [
//   {
//     title: "Demander un projet",
//     desc: "Avec un concept clair en tête, nous passons à la phase de conception. C'est ici que je regroupe les éléments visuels, les interfaces conviviales et tous les composants créatifs qui permettront à votre projet de se démarquer.",
//     withPatern: true,
//   },
//   {
//     title: "Idéation",
//     desc: "Dans cette étape, la créativité occupe une place centrale. Je me lance dans des séances de brainstorming pour conceptualiser votre projet. C'est ici que j'explore des solutions innovantes et des approches artistiques pour donner vie à votre vision.",
//     withPatern: false,
//   },
//   {
//     title: "Conception",
//     desc: "Avec un concept clair en tête, nous passons à la phase de conception. C'est ici que je regroupe les éléments visuels, les interfaces conviviales et tous les composants créatifs qui permettront à votre projet de se démarquer.",
//     withPatern: false,
//   },
//   {
//     title: "Feedback",
//     desc: "Dans cette étape, la créativité occupe une place centrale. Je me lance dans des séances de brainstorming pour conceptualiser votre projet. C'est ici que j'explore des solutions innovantes et des approches artistiques pour donner vie à votre vision.",
//     withPatern: true,
//   },
//   {
//     title: "Développement",
//     desc: "Une fois la conception finalisée, nous passons au développement de votre projet. Notre équipe d'experts chevronnés s'attaque à chaque aspect technique, s'assurant que le résultat final répond à vos attentes et dépasse vos exigences.",
//     withPatern: true,
//   },
//   {
//     title: "Lancement",
//     desc: "Dans cette étape, la créativité occupe une place centrale. Je me lance dans des séances de brainstorming pour conceptualiser votre projet. C'est ici que j'explore des solutions innovantes et des approches artistiques pour donner vie à votre vision.",
//     withPatern: false,
//   },
// ];

export const projectSteps = [
  {
    title: "Demande de projet",
    desc: "Votre voyage commence ici. Présentez-nous votre idée ou votre besoin, et ensemble, nous établirons les bases de ce qui deviendra votre projet. C'est le moment de poser les fondations et de définir les grandes lignes.",
    withPattern: true,
  },
  {
    title: "Conceptualisation",
    desc: "Cette phase est le cœur créatif du processus. À travers des sessions de brainstorming et d'échanges d'idées, nous donnons forme à votre vision. Nous explorons différentes avenues créatives pour concevoir une solution unique et innovante.",
    withPattern: false,
  },
  {
    title: "Conception",
    desc: "Avec une direction claire établie, nous nous attaquons à la conception détaillée de votre projet. Cette étape implique le design des interfaces, la création d'éléments visuels et la planification des interactions pour garantir une expérience utilisateur optimale.",
    withPattern: false,
  },
  {
    title: "Feedback",
    desc: "Votre retour est essentiel. Après vous avoir présenté les premières ébauches et maquettes, nous affinons le projet en fonction de vos commentaires. Cette collaboration continue garantit que le résultat final correspondra parfaitement à vos attentes.",
    withPattern: true,
  },
  {
    title: "Développement",
    desc: "C'est là que nos développeurs prennent le relais. Avec précision et expertise, ils transforment les concepts et designs en une réalité fonctionnelle. En utilisant les dernières technologies, nous nous assurons que votre projet est sécurisé, réactif et prêt à l'emploi.",
    withPattern: true,
  },
  {
    title: "Lancement",
    desc: "Le moment tant attendu. Après un dernier cycle de tests et d'ajustements, votre projet est prêt à être dévoilé au monde. Nous vous accompagnons dans cette étape cruciale, assurant un lancement fluide pour un impact maximal.",
    withPattern: false,
  },
];

export const reviews = [
  {
    author: " Anish Parikh, CEO",
    profilePicture: "/assets/images/clientProfile.png",
    title: "KiamTech is very fast and efficient",
    comment:
      "Whenever we ask them for something, they have it ready by the next day. If we can’t provide immediate feedback, they keep working on other things to avoid wasting time waiting.",
  },
  {
    author: " Anish Parikh, CEO",
    profilePicture: "/assets/images/clientProfile.png",
    title: "KiamTech is very fast and efficient",
    comment:
      "Whenever we ask them for something, they have it ready by the next day. If we can’t provide immediate feedback, they keep working on other things to avoid wasting time waiting.",
  },
  {
    author: " Anish Parikh, CEO",
    profilePicture: "/assets/images/clientProfile.png",
    title: "KiamTech is very fast and efficient",
    comment:
      "Whenever we ask them for something, they have it ready by the next day. If we can’t provide immediate feedback, they keep working on other things to avoid wasting time waiting.",
  },
  {
    author: " Anish Parikh, CEO",
    profilePicture: "/assets/images/clientProfile.png",
    title: "KiamTech is very fast and efficient",
    comment:
      "Whenever we ask them for something, they have it ready by the next day. If we can’t provide immediate feedback, they keep working on other things to avoid wasting time waiting.",
  },
  {
    author: " Anish Parikh, CEO",
    profilePicture: "/assets/images/clientProfile.png",
    title: "KiamTech is very fast and efficient",
    comment:
      "Whenever we ask them for something, they have it ready by the next day. If we can’t provide immediate feedback, they keep working on other things to avoid wasting time waiting.",
  },
  {
    author: " Anish Parikh, CEO",
    profilePicture: "/assets/images/clientProfile.png",
    title: "KiamTech is very fast and efficient",
    comment:
      "Whenever we ask them for something, they have it ready by the next day. If we can’t provide immediate feedback, they keep working on other things to avoid wasting time waiting.",
  },
  {
    author: " Anish Parikh, CEO",
    profilePicture: "/assets/images/clientProfile.png",
    title: "KiamTech is very fast and efficient",
    comment:
      "Whenever we ask them for something, they have it ready by the next day. If we can’t provide immediate feedback, they keep working on other things to avoid wasting time waiting.",
  },
  {
    author: " Anish Parikh, CEO",
    profilePicture: "/assets/images/clientProfile.png",
    title: "KiamTech is very fast and efficient",
    comment:
      "Whenever we ask them for something, they have it ready by the next day. If we can’t provide immediate feedback, they keep working on other things to avoid wasting time waiting.",
  },
];

export const policyLinks = [
  { label: "Termes de service", path: "terms&Conditions" },
  { label: "Politique d’utilisation", path: "politicOfUsage" },
  { label: "Politique de Confidentialité", path: "privacyPolicy" },
  { label: "Politique de cookies", path: "cookiePolicy" },
];

export const termsAndConditions = [
  "Termes de Service de Kiam Tech",
  "Introduction",
  "Bienvenue sur Kiam Tech. En accédant à notre site web et en utilisant nos services, vous acceptez les termes et conditions suivants. Ces termes régissent votre accès et votre utilisation des services de développement web et mobile proposés par Kiam Tech, y compris tout contenu, fonctionnalité, et service offert sur ou via [VotreSiteWeb.com].",
  "Accès aux Services",
  "L'accès à nos services est temporaire, et nous nous réservons le droit de retirer ou de modifier les services que nous fournissons sans préavis. Nous ne serons pas responsables si, pour une raison quelconque, notre site est indisponible à un moment ou une période donnée.",
  "Propriété Intellectuelle",
  "Tout le contenu publié sur notre site, y compris les textes, designs, graphiques, et logos, est la propriété de Kiam Tech et est protégé par les lois françaises et internationales sur le droit d'auteur.",
  "Utilisation Acceptable",
  "Vous vous engagez à ne pas utiliser notre site ou nos services d'une manière qui pourrait les endommager, les désactiver, les surcharger ou les altérer, ou interférer avec l'utilisation par d'autres parties.",
  "Modification des Termes",
  "Nous pouvons réviser ces termes d'utilisation à tout moment en modifiant cette page. Vous êtes attendu de vérifier cette page de temps à autre pour prendre connaissance de tout changement que nous y avons apporté, car ils vous engagent.",
];

export const politicOfUsage = [
  "Politique d’Utilisation de Kiam Tech",
  "Bienvenue chez Kiam Tech. Cette Politique d'Utilisation définit les règles et directives concernant l'utilisation de notre site web et de nos services. En accédant à notre site web et en utilisant nos services, vous acceptez de vous conformer à cette politique ainsi qu'à nos Termes de Service.",
  "Utilisation Autorisée",
  "Vous êtes autorisé à utiliser notre site web et nos services pour vos besoins légitimes de développement web et mobile, y compris mais sans s'y limiter à :",
  "- La consultation d'informations sur nos services.",
  "- La prise de contact avec nous via les formulaires mis à disposition.",
  "- La souscription à nos services en ligne.",
  "Restrictions d’Utilisation",
  "En utilisant notre site web et nos services, vous vous engagez à ne pas :",
  "- Utiliser le site ou les services à des fins illégales ou frauduleuses.",
  "- Copier, modifier ou distribuer le contenu du site sans l'autorisation écrite préalable de Kiam Tech.",
  "- Utiliser des dispositifs automatiques ou des processus manuels pour surveiller ou copier le contenu du site web sans notre consentement préalable.",
  "- Utiliser notre site web ou nos services d'une manière qui pourrait les endommager, les désactiver, les surcharger, ou les altérer.",
  "- Tenter d'obtenir un accès non autorisé à tout partie du site, à d'autres comptes, systèmes informatiques ou réseaux connectés au serveur de Kiam Tech, que ce soit par piratage, extraction de mot de passe ou tout autre moyen.",
  "- Utiliser le site web ou les services pour transmettre des virus informatiques, des chevaux de Troie, des vers, des bombes logiques, ou tout autre matériel malveillant.",
  '- Utiliser le site web ou les services pour envoyer de la publicité ou du matériel promotionnel non sollicité, communément appelé "spam".',
  "Sécurité du Site Web",
  "Nous nous efforçons de sécuriser notre site web contre les accès non autorisés, l'utilisation ou la divulgation. Cependant, nous ne pouvons garantir la sécurité absolue des informations envoyées à notre site web, à vos risques.",
  "Modifications de la Politique d’Utilisation",
  "Kiam Tech se réserve le droit de modifier cette Politique d'Utilisation à tout moment. Les modifications entreront en vigueur dès leur publication sur le site web. Votre utilisation continue du site après de telles modifications constitue votre accord de suivre et d'être lié par la Politique d'Utilisation modifiée.",
  "Contact",
  "Pour toute question ou préoccupation concernant cette politique, veuillez nous contacter via les moyens de communication fournis sur notre site web.",
];

export const privacyPolicy = [
  "Politique de Confidentialité de Kiam Tech",
  "Introduction",
  "Votre vie privée est de la plus haute importance pour Kiam Tech. Nous nous engageons à protéger les informations personnelles que vous partagez avec nous. Cette politique de confidentialité explique comment nous collectons, utilisons, protégeons, et dans certains cas, partageons vos informations personnelles.",
  "Collecte des Informations",
  "Nous collectons des informations personnelles lorsque vous utilisez nos services, visitez notre site web, ou communiquez avec nous. Les types d'informations que nous pouvons collecter incluent, mais ne se limitent pas à, votre nom, adresse e-mail, numéro de téléphone, ainsi que toute autre information que vous choisissez de nous fournir.",
  "Utilisation des Informations",
  "Les informations que nous collectons sont utilisées pour :",
  "- Fournir, opérer et maintenir nos services;",
  "- Améliorer, personnaliser et élargir nos services;",
  "- Comprendre et analyser comment vous utilisez nos services;",
  "- Développer de nouveaux produits, services, caractéristiques et fonctionnalités;",
  "- Communiquer avec vous, directement ou à travers l'un de nos partenaires, y compris pour le service client, pour vous fournir des mises à jour et d'autres informations relatives au service, et à des fins de marketing et de promotion;",
  "- Vous envoyer des emails;",
  "- Trouver et prévenir la fraude.",
  "Partage des Informations",
  "Nous nous engageons à ne pas vendre, louer, ou partager vos informations personnelles avec des tiers à des fins de marketing sans votre consentement explicite. Cependant, nous pouvons partager les informations collectées dans les situations suivantes :",
  "- Conformément à la loi, sur ordre d'un tribunal, ou si nécessaire pour d'autres exigences légales;",
  "- Pour protéger les droits, la propriété ou la sécurité de Kiam Tech, de nos clients ou d'autres parties.",
  "Sécurité des Informations",
  "Nous prenons la sécurité de vos informations personnelles au sérieux et utilisons des mesures raisonnables pour protéger contre la perte, l'usage abusif et l'altération des informations sous notre contrôle. Cependant, aucun système de transmission ou de stockage d'informations n'est complètement sécurisé.",
  "Vos Droits",
  "Vous avez le droit de demander l'accès, la correction, la suppression ou la limitation de l'utilisation et de la divulgation de vos informations personnelles que nous détenons. Pour exercer ces droits, veuillez nous contacter à l'adresse fournie.",
  "Modifications à cette Politique",
  "Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Les modifications entreront en vigueur immédiatement après leur publication sur notre site web. Nous vous encourageons à consulter régulièrement cette politique pour rester informé de notre gestion de vos informations.",
  "Contactez-nous",
  "Si vous avez des questions ou des préoccupations concernant cette politique de confidentialité, veuillez nous contacter via les moyens de communication fournis sur notre site web.",
];

export const cookiePolicy = [
  "Politique de Cookies de Kiam Tech",
  "Introduction",
  "Kiam Tech utilise des cookies pour améliorer votre expérience sur notre site web, analyser notre trafic, et pour des fins publicitaires. En utilisant notre site, vous consentez à notre utilisation des cookies conformément à cette Politique de Cookies.",
  "Qu'est-ce qu'un cookie ?",
  "Un cookie est un petit fichier texte stocké sur votre ordinateur ou appareil mobile par un site web que vous visitez. Il permet au site de se souvenir de vos actions et préférences (telles que la connexion, la langue, la taille de la police et d'autres préférences d'affichage) sur une période de temps, donc vous n'avez pas à les ré-entrer chaque fois que vous revenez sur le site ou naviguez d'une page à une autre.",
  "Comment nous utilisons les cookies ?",
  "Les cookies que nous utilisons sont classés en plusieurs catégories :",
  "- Cookies Essentiels : Ces cookies sont nécessaires pour que le site web fonctionne correctement. Ils vous permettent de naviguer sur notre site et d'utiliser ses fonctionnalités.",
  "- Cookies de Performance : Ces cookies nous aident à comprendre comment les visiteurs interagissent avec notre site web, en fournissant des informations sur les zones visitées, le temps passé sur le site, et tout problème rencontré, comme des messages d'erreur.",
  "- Cookies Fonctionnels : Ces cookies permettent à notre site de se souvenir des choix que vous avez faits (comme votre nom d'utilisateur, la langue ou la région où vous vous trouvez) et fournissent des fonctionnalités améliorées et plus personnelles.",
  "- Cookies de Publicité : Ces cookies sont utilisés pour rendre les messages publicitaires plus pertinents pour vous. Ils empêchent également la même publicité de réapparaître continuellement, assurent que les publicités sont correctement affichées, et, dans certains cas, sélectionnent des publicités basées sur vos intérêts.",
  "Gestion des cookies",
  "Vous pouvez contrôler et/ou supprimer les cookies comme vous le souhaitez – pour plus de détails, voir aboutcookies.org. Vous pouvez supprimer tous les cookies qui se trouvent déjà sur votre ordinateur et vous pouvez configurer la plupart des navigateurs pour empêcher leur installation. Si vous faites cela, cependant, vous devrez peut-être ajuster manuellement certaines préférences chaque fois que vous visitez un site et certains services et fonctionnalités peuvent ne pas fonctionner.",
  "Votre choix concernant les cookies",
  "Si vous souhaitez limiter ou bloquer les cookies définis par notre site, ou par tout autre site, vous pouvez le faire via les paramètres de votre navigateur. La fonction d'Aide de votre navigateur devrait vous fournir les instructions nécessaires.",
  "Modifications de la Politique de Cookies",
  "Nous pouvons mettre à jour cette Politique de Cookies de temps à autre. Nous vous encourageons à consulter cette page régulièrement pour rester informé des changements et de la manière dont nous utilisons les cookies.",
  "Contactez-nous",
  "Si vous avez des questions sur notre utilisation des cookies, n'hésitez pas à nous contacter via les canaux de communication disponibles sur notre site web.",
];

export const contactDetails = {
  email: "contact@kiamtech.com",
  phone: "+33 9 77 19 57 61",
  adress: "La Défense, 92000 Nanterre",
};
